<template>
    <section class="hero is-primary is-fullheight">
        <div class="hero-body is-justify-content-center">
            <div class="">
                <p class="subtitle">
                    Welcome to
                </p>
                <p class="title is-1 mt-3">
                    Monigrow
                </p>
                <section>
                    <b-button
                        size="is-large"
                        type="is-primary"
                        expanded
                        @click="onProfile"
                        >Profile</b-button
                    >
                </section>
                <section>
                    <b-button
                        size="is-large"
                        type="is-primary"
                        expanded
                        @click="onSignin"
                        >Sign in</b-button
                    >
                </section>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: "Home",
    components: {
        // HelloWorld,
    },
    methods: {
        onProfile() {
            this.$router.push("/profile");
        },
        onSignin() {
            this.$router.push("/auth");
        },
    },
};
</script>
