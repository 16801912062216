import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import dashboard from "@/store/modules/dashboard";
import mq from "@/store/modules/mq";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        user,
        dashboard,
        mq
    },
});
