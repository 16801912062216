/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      language
      things {
        items {
          id
          userID
          thingID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        language
        things {
          nextToken
        }
        dashboards {
          nextToken
        }
        rules {
          nextToken
        }
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserThing = /* GraphQL */ `
  query GetUserThing($id: ID!) {
    getUserThing(id: $id) {
      id
      userID
      thingID
      role
      createdAt
      updatedAt
    }
  }
`;
export const listUserThings = /* GraphQL */ `
  query ListUserThings(
    $filter: ModelUserThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserThings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        thingID
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThing = /* GraphQL */ `
  query GetThing($thingID: String!) {
    getThing(thingID: $thingID) {
      thingID
      name
      expiredDate
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listThings = /* GraphQL */ `
  query ListThings(
    $thingID: String
    $filter: ModelThingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThings(
      thingID: $thingID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        thingID
        name
        expiredDate
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThingStream = /* GraphQL */ `
  query GetThingStream($topic: ID!, $timestamp: AWSTimestamp!) {
    getThingStream(topic: $topic, timestamp: $timestamp) {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const listThingStreams = /* GraphQL */ `
  query ListThingStreams(
    $topic: ID
    $timestamp: ModelIntKeyConditionInput
    $filter: ModelThingStreamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThingStreams(
      topic: $topic
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        topic
        timestamp
        thingID
        fieldName
        mean
        count
        min
        max
        AggregatorID
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThingShadow = /* GraphQL */ `
  query GetThingShadow($thingID: String!) {
    getThingShadow(thingID: $thingID) {
      thingID
      timestamp
      desired
      reported
      delta
      metadata
      createdAt
      updatedAt
    }
  }
`;
export const listThingShadows = /* GraphQL */ `
  query ListThingShadows(
    $thingID: String
    $filter: ModelThingShadowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThingShadows(
      thingID: $thingID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        thingID
        timestamp
        desired
        reported
        delta
        metadata
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThingShadowHistory = /* GraphQL */ `
  query GetThingShadowHistory($thingID: String!, $timestamp: AWSTimestamp!) {
    getThingShadowHistory(thingID: $thingID, timestamp: $timestamp) {
      thingID
      timestamp
      desired
      reported
      delta
      metadata
      action
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const listThingShadowHistorys = /* GraphQL */ `
  query ListThingShadowHistorys(
    $thingID: String
    $timestamp: ModelIntKeyConditionInput
    $filter: ModelThingShadowHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThingShadowHistorys(
      thingID: $thingID
      timestamp: $timestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        thingID
        timestamp
        desired
        reported
        delta
        metadata
        action
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThingCredentials = /* GraphQL */ `
  query GetThingCredentials($username: String!) {
    getThingCredentials(username: $username) {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const listThingCredentialss = /* GraphQL */ `
  query ListThingCredentialss(
    $username: String
    $filter: ModelThingCredentialsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThingCredentialss(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        password
        thingID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThingConsole = /* GraphQL */ `
  query GetThingConsole($thingID: String!) {
    getThingConsole(thingID: $thingID) {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const listThingConsoles = /* GraphQL */ `
  query ListThingConsoles(
    $thingID: String
    $filter: ModelThingConsoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listThingConsoles(
      thingID: $thingID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        thingID
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAggregator = /* GraphQL */ `
  query GetAggregator($id: ID!) {
    getAggregator(id: $id) {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAggregators = /* GraphQL */ `
  query ListAggregators(
    $filter: ModelAggregatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAggregateTask = /* GraphQL */ `
  query GetAggregateTask($id: ID!) {
    getAggregateTask(id: $id) {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAggregateTasks = /* GraphQL */ `
  query ListAggregateTasks(
    $filter: ModelAggregateTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregateTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        thingID
        AggregatorID
        enable
        topics {
          nextToken
        }
        detectedFields {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAggregateTaskTopic = /* GraphQL */ `
  query GetAggregateTaskTopic($id: ID!) {
    getAggregateTaskTopic(id: $id) {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const listAggregateTaskTopics = /* GraphQL */ `
  query ListAggregateTaskTopics(
    $filter: ModelAggregateTaskTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregateTaskTopics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AggregateTaskID
        topic
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAggregateTaskDetectedField = /* GraphQL */ `
  query GetAggregateTaskDetectedField($id: ID!) {
    getAggregateTaskDetectedField(id: $id) {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const listAggregateTaskDetectedFields = /* GraphQL */ `
  query ListAggregateTaskDetectedFields(
    $filter: ModelAggregateTaskDetectedFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAggregateTaskDetectedFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AggregateTaskID
        field
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnomalyDetector = /* GraphQL */ `
  query GetAnomalyDetector($id: ID!) {
    getAnomalyDetector(id: $id) {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAnomalyDetectors = /* GraphQL */ `
  query ListAnomalyDetectors(
    $filter: ModelAnomalyDetectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnomalyDetectors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnomalyDetectorTask = /* GraphQL */ `
  query GetAnomalyDetectorTask($id: ID!) {
    getAnomalyDetectorTask(id: $id) {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAnomalyDetectorTasks = /* GraphQL */ `
  query ListAnomalyDetectorTasks(
    $filter: ModelAnomalyDetectorTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnomalyDetectorTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlertStream = /* GraphQL */ `
  query GetAlertStream($id: ID!) {
    getAlertStream(id: $id) {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const listAlertStreams = /* GraphQL */ `
  query ListAlertStreams(
    $filter: ModelAlertStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertStreams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        creatorID
        thingID
        topic
        field
        value
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnomalyDetectorTaskAlert = /* GraphQL */ `
  query GetAnomalyDetectorTaskAlert($id: ID!) {
    getAnomalyDetectorTaskAlert(id: $id) {
      id
      taskID
      alertID
      alert {
        id
        userID
        type
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAnomalyDetectorTaskAlerts = /* GraphQL */ `
  query ListAnomalyDetectorTaskAlerts(
    $filter: ModelAnomalyDetectorTaskAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnomalyDetectorTaskAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        alertID
        alert {
          id
          userID
          type
          token
          enable
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      userID
      type
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        type
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShadowDispatcher = /* GraphQL */ `
  query GetShadowDispatcher($id: ID!) {
    getShadowDispatcher(id: $id) {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShadowDispatchers = /* GraphQL */ `
  query ListShadowDispatchers(
    $filter: ModelShadowDispatcherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShadowDispatchers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        things {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShadowDispatcherThing = /* GraphQL */ `
  query GetShadowDispatcherThing($id: ID!) {
    getShadowDispatcherThing(id: $id) {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const listShadowDispatcherThings = /* GraphQL */ `
  query ListShadowDispatcherThings(
    $filter: ModelShadowDispatcherThingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShadowDispatcherThings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thingID
        thing {
          thingID
          name
          expiredDate
          serial
          description
          tags
          other
          createdAt
          updatedAt
        }
        ShadowDispatcherID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDashboard = /* GraphQL */ `
  query GetDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards(
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        themeColor
        dashboardItems {
          nextToken
        }
        userID
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDashboardItem = /* GraphQL */ `
  query GetDashboardItem($id: ID!) {
    getDashboardItem(id: $id) {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const listDashboardItems = /* GraphQL */ `
  query ListDashboardItems(
    $filter: ModelDashboardItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        x
        y
        width
        height
        type
        description
        option
        timeSeriesSource
        realtimeSource
        dashboardID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRule = /* GraphQL */ `
  query GetRule($id: ID!) {
    getRule(id: $id) {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const listRules = /* GraphQL */ `
  query ListRules(
    $filter: ModelRuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        input
        rule
        parameter
        description
        running
        userID
        output
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listThingStreamByThingId = /* GraphQL */ `
  query ListThingStreamByThingId(
    $thingID: String
    $sortDirection: ModelSortDirection
    $filter: ModelThingStreamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThingStreamByThingID(
      thingID: $thingID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        topic
        timestamp
        thingID
        fieldName
        mean
        count
        min
        max
        AggregatorID
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
