import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Amplify, { Hub } from "aws-amplify";
import aws_exports from "./aws-exports.js";
import {
    applyPolyfills,
    defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import Buefy from "buefy";
// import 'buefy/dist/buefy.css'
import "@/styles/style.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
// internal icons
import {
    faCheck,
    faCheckCircle,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faArrowUp,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretUp,
    faUpload,
    faDownload,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";

import { faLine } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

console.log({ faLine });
library.add(
    faCheck,
    faCheckCircle,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faArrowUp,
    faAngleRight,
    faAngleLeft,
    faAngleDown,
    faEye,
    faEyeSlash,
    faCaretDown,
    faCaretUp,
    faUpload,
    faDownload,
    faExternalLinkAlt,
    faLine
);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
    defaultIconComponent: "vue-fontawesome",
    defaultIconPack: "fas",
});

import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);

console.log({ aws_exports });
Amplify.configure(aws_exports);
applyPolyfills().then(() => {
    defineCustomElements(window);
});

import { InfluxDB } from "@influxdata/influxdb-client";
console.log(process.env);
const INFLUX_ENDPOINT = process.env.VUE_APP_INFLUX_ENDPOINT || "";
const INFLUX_ALERT_ENDPOINT = process.env.VUE_APP_INFLUX_ALERT_ENDPOINT || "";
const ORG_NAME = process.env.VUE_APP_ORG_NAME || "";
console.log("org:", ORG_NAME, INFLUX_ENDPOINT);

Vue.prototype.$THING_CREDENTIAL = process.env.VUE_APP_THING_CREDENTIAL;
Vue.prototype.$MQTT = process.env.VUE_APP_MQTT;
Vue.prototype.$INFLUX_ENDPOINT = INFLUX_ENDPOINT;
Vue.prototype.$INFLUX_ALERT_ENDPOINT = INFLUX_ALERT_ENDPOINT;
Vue.prototype.$INFLUX_DEFAULT_DB = "AVILON_IOT";

Vue.prototype.$QUERY_API = new InfluxDB({
    url: INFLUX_ENDPOINT,
    token: undefined,
}).getQueryApi(ORG_NAME);

Vue.config.productionTip = false;

Hub.listen("auth", (data) => {
    console.log("auth event:", data);
    switch (data.payload.event) {
        case "signIn":
            console.log("sign in!");
            break;
        case "signIn_failure":
            console.log("sign in failure!");
            break;
        default:
            break;
    }
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
