const state = {
    authState: undefined as undefined | string,
    user: {},
};

const getters = {
    user: (state) => {
        return state.user
    },
};

const mutations = {
    reset(state) {
        state.authState = undefined;
        state.user = {};
    },
    setAuthState(state, value): void {
        state.authState = value;
    },
    setUser(state, value): void {
        state.user = value;
    },
};

const actions = {};

const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default auth;
