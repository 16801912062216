/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:0ce0b735-6529-42a5-9fc8-ae8baabf59bc",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_ANUH05EVq",
    "aws_user_pools_web_client_id": "35pvedljfsh6jnld942pp79ovv",
    "oauth": {
        "domain": "aviloniot-production.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://monigrow.com/",
        "redirectSignOut": "https://monigrow.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://hsmx5oju75hbtf43txk3enhzeq.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sxpmnxv2urc5vmlhh2w6ud4mmy",
    "aws_cloud_logic_custom": [
        {
            "name": "MQTTAuthentication",
            "endpoint": "https://oajlle0pcf.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        },
        {
            "name": "api",
            "endpoint": "https://pub2mkl0yg.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "thing-stream-archive135345-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
