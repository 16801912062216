import axios from "axios";
import mqtt from "mqtt";

let client: any = null;

const state = {
    messages: {},
    state: null,
    connectivity: {},
    connectivityCounter: 0,
    messageCounter: 0,
};

const getters = {
    currentState: (state) => {
        return state.state;
    },
    isConnected: (state) => {
        return state.state === "connected";
    },
    messageByTopic: (state) => (topic) => {
        return state.messages[topic];
    },
    messageCounter: (state) => {
        return state.messageCounter;
    },
    connectivity: (state) => (thingID) => {
        return state.connectivity[thingID];
    },
    connectivityCounter: (state) => {
        return state.connectivityCounter;
    },
};

const mutations = {
    setMQTTState(state, value) {
        state.state = value;
    },
    updateConnectivity(state, { thingID, connected }) {
        state.connectivity[thingID] = connected;
        state.connectivityCounter = state.connectivityCounter + 1;
    },
    updateRealtimeData(state, { topic, value }) {
        state.messageCounter = state.messageCounter + 1;
        state.messages[topic] = value;
    },
};

const actions = {
    async MqttConnect(context, { broker_url, auth_url }) {
        // console.log("connect MQTT");
        try {
            const res = await axios.get(auth_url, {
                params: {
                    ClientID:
                        "web" +
                        Math.random()
                            .toString(16)
                            .substr(2, 8),
                    Expired: "100h",
                },
            });
            const clientId = res.data.ClientID;
            const username = res.data.Username;
            const password = res.data.Password;
            const keepalive = 10;

            client = mqtt.connect(broker_url, {
                clientId,
                username,
                password,
                keepalive,
            });

            client.on("connect", function() {
                console.log("connected");
                context.commit("setMQTTState", "connected");
            });

            client.on("message", function(topic, message) {
                const layers = topic.split("/");
                if (layers[1] === "connected") {
                    context.commit("updateConnectivity", {
                        thingID: layers[0],
                        connected: message.toString() === "true" ? true : false,
                    });
                    return;
                }

                if (layers[2] === "connected") {
                    context.commit("updateConnectivity", {
                        thingID: layers[0],
                        connected: message.toString() === "true" ? true : false,
                    });
                    return;
                }

                const jsonData = JSON.parse(message.toString());
                context.commit("updateRealtimeData", {
                    topic,
                    value: jsonData,
                });
            });

            client.on("offline", function() {
                console.log("offline");
                context.commit("setMQTTState", "offline");
            });
        } catch (error) {
            console.log("err", error);
        }
    },
    async MqttSubscribe(context, topic) {
        try {
            client.subscribe(topic, function(err) {
                if (err) {
                    console.warn("subscribe err", err);
                }
            });
        } catch (error) {
            console.log("error", error);
        }
    },
};

const mq = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default mq;
