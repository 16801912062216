import { API } from "aws-amplify";
import * as query from "@/graphql/queries";
import * as mutation from "@/graphql/mutations";

function filter(list: Array<any>, key: string, value: any) {
    return list?.filter((i: any) => i[key as any] === value) || [];
}

const state = {
    dashboard: {},
};

const getters = {
    valueBadgeList: (state) => {
        return filter(
            state.dashboard?.dashboardItems?.items,
            "type",
            "valueBadge"
        );
    },
    notificationList: (state) => {
        return filter(
            state.dashboard?.dashboardItems?.items,
            "type",
            "notification"
        );
    },
    lineChartList: (state) => {
        return filter(
            state.dashboard?.dashboardItems?.items,
            "type",
            "lineChart"
        );
    },
};

const mutations = {
    reset(state){
        state.dashboard = {}
    },
    setDashboard(state, value) {
        state.dashboard = value;
    },
};

const actions = {
    async getDashboard(context, { id }) {
        const response: any = await API.graphql({
            query: query.getDashboard,
            variables: {
                id,
            },
        });
        const { data } = response;
        console.log({ response });
        if (data) {
            context.commit("setDashboard", data.getDashboard);
            return data.getDashboard;
        }
    },
    async updateItem(context, item) {
        console.log("updateItem: ", { item });
        delete item["createdAt"];
        delete item["updatedAt"];
        const response: any = await API.graphql({
            query: mutation.updateDashboardItem,
            variables: {
                input: item,
            },
        });
        console.log("updateItem:", { response });
    },
};

const dashboard = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default dashboard;
