import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import multiguard from "vue-router-multiguard";
import Home from "../views/Home.vue";
import { authenticationGuard } from "./guard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/profile/:username?/:dashboardid?",
        name: "Profile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        beforeEnter: multiguard([authenticationGuard]),
    },
    {
        path: "/auth",
        name: "Auth",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "auth" */ "../views/Authentication.vue"
            ),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
